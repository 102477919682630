/**
 * @summary LANDING PAGES: This is any landing page for a microsite, https://domain.com/{slug}/landing.
 * This points to wordpress Landing Pages content types
 */
import React from 'react';
import PageContainer from 'components/global/PageContainer';
import PageSectionLayout from 'components/global/PageSectionLayout';
import getLandingPageData from 'app-requests/content-type-loaders/landing-page-props';
import handleServerRedirect from 'utils/handleServerRedirect';
import { APIError } from 'utils/errors/APIError';
import { APITransformError } from 'utils/errors/APITransformError';
import { SiteTypeError } from 'utils/errors/SiteTypeError';
import { LogError } from 'utils/logging';
import {
  clickPortalRedirect,
  defaultRedirect,
  notFoundRedirect,
  serverMaintenanceRedirect,
  SITE_TYPE,
} from 'consts';
import { GetServerSidePropsResult, GetServerSidePropsContext } from 'next';
import { ServerSidePageProps } from 'types';

function LandingPage(props: { pageProps: ServerSidePageProps }): JSX.Element {
  const {
    pageProps: { pageSections, ogImg, ...globalPageProps },
  } = props;

  return (
    <PageContainer pageProps={{ ...globalPageProps, ogImg }}>
      <PageSectionLayout pageSections={pageSections} isLanding />
    </PageContainer>
  );
}

/**
 * @summary this is the Nextjs function that will get the server side props.
 * this will call all needed API to create the Landing Page
 */
export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<ServerSidePageProps | object>> {
  try {
    const pageProps = await getLandingPageData(context);
    return { props: pageProps };
  } catch (error: unknown) {
    if (
      error instanceof APIError &&
      error.statusCode === 404 &&
      context.req.url !== defaultRedirect
    ) {
      handleServerRedirect(defaultRedirect, context);
      return { props: {} };
    }

    if (error instanceof APIError && error.statusCode === 404) {
      handleServerRedirect(notFoundRedirect, context);
      return { props: {} };
    }

    if (
      error instanceof SiteTypeError &&
      error.siteType === SITE_TYPE.CLICK_PORTAL
    ) {
      handleServerRedirect(clickPortalRedirect, context);
      return { props: {} };
    }

    if (error instanceof APIError) {
      LogError(error, {
        statusCode: error.statusCode,
        endpoint: error.endpoint,
        route: 'landing',
      });
    } else if (error instanceof APITransformError) {
      LogError(error, {
        method: error.method,
        route: 'landing',
      });
    } else {
      LogError(error as Error, {
        route: 'landing',
      });
    }

    handleServerRedirect(serverMaintenanceRedirect, context, 302);
    return { props: {} };
  }
}

export default LandingPage;
